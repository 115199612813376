/* eslint-disable require-jsdoc */
import React, { useEffect } from 'react';
import MainRoutes from './main-routes';
import CreateGlobalStyle from './global-style';
import { ParallaxProvider } from 'react-scroll-parallax';
import lozad from 'lozad';

function App() {
    useEffect(() => {
        const el = document.querySelectorAll(['img', 'video']);
        const observer = lozad(el);
        observer.observe();
    });

    useEffect(() => {
        const chatElem = document.querySelector('#recx-root');
        if (chatElem) {
            chatElem.style.display = 'none';
        }
        setTimeout(() => {
            if (chatElem) {
                chatElem.style.display = 'block';
            }
        }, 6000);
    }, []);

    return (
        <>
            <CreateGlobalStyle />
            <ParallaxProvider>
                <MainRoutes />
            </ParallaxProvider>
        </>
    );
}

export default App;
